import { Trans } from "@lingui/macro";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import { motion } from "framer-motion";
import Container from "../../../components/Container";
import HeroLanding from "../../../components/HeroLanding";
import SecondaryButton from "../../../components/SecondaryButton";
import CardColor from "../../../components/CardColor";
import Seo from "../../../components/seo";
import Header from "../../../components/Header";
import { theme } from "../../../themeV2";
import greenIcon from "../../../images/solutions/esg/esgeverything/icon-green.png";
import purpleIcon from "../../../images/solutions/esg/esgeverything/icon-purple.png";
import orangeIcon from "../../../images/solutions/esg/esgeverything/icon-orange.png";
import heroImg from "../../../images/solutions/esg/esgeverything/hero.png";
import heroKindImg from "../../../images/solutions/esg/esgeverything/hero-logo.png";
import searchVideo from "../../../images/solutions/esg/esgeverything/search-video.mp4";
import monitoringImg from "../../../images/solutions/esg/esgeverything/monitoring.png";
import reportingImg from "../../../images/solutions/esg/esgeverything/reporting.png";
import ProductModal from "../../../components/ProductModal";

const metadata = {
  title: "ESGeverything",
  description:
    "A comprehensive ESG audit tool to assess portfolios of Funds, ETFs, Equities, Corporate Bonds, Government Bonds, Green and Social Bonds.",
};

const CARDS_ITEMS = [
  {
    icon: purpleIcon,
    color: theme.palette.colors.purple.main,
    title: <Trans>Search ESG ratings easily</Trans>,
    description: (
      <Trans>
        We offer access to extensive ESG ratings generated by our proprietary models developed over 10+ years of research
      </Trans>
    ),
  },
  {
    icon: greenIcon,
    color: theme.palette.colors.green.dark,
    title: <Trans>See how it all fits together</Trans>,
    description: (
      <Trans>
        ESGeverything helps investors understand where various ESG risks come from and how they can affect your overall portfolio
      </Trans>
    ),
  },
  {
    icon: orangeIcon,
    color: theme.palette.colors.orange.dark,
    title: <Trans>Improve your ESG reporting</Trans>,
    description: (
      <Trans>
        Optimise the ESG profile of your portfolio or adjust its Environmental, Social or Governance features to align it with your clients’ values
      </Trans>
    ),
  },
];

const getAnimationProps = (direction) => ({
  initial: "hidden",
  whileInView: "visible",
  viewport: { once: true, amount: 0.8 },
  transition: { duration: 0.8 },
  variants: {
    hidden: { opacity: 0, y: direction === "down" ? 70 : -70 },
    visible: { opacity: 1, y: 0 },
  },
});

function AnimatedDiv({ isMobile, direction, children }) {
  if (isMobile) {
    return <div>{children}</div>;
  }
  return <motion.div {...getAnimationProps(direction)}>{children}</motion.div>;
}

function EsgEverything() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showModal, setShowModal] = useState(false);

  const renderRequestButton = () => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => setShowModal(true)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const preTitle = ({
    color = `${theme.palette.colors.green.dark}`,
    text,
    align = "center",
  }) => {
    return (
      <Typography
        fontWeight={700}
        color={color}
        textAlign={align}
        fontSize={{ xs: "18px", sm: "20px" }}
      >
        {text}
      </Typography>
    );
  };

  const renderCard = ({ color, icon, title, description, key }) => {
    return (
      <Grid item xs={12} sm={6} md={4} sx={{ transform: { sm: key % 3 === 0 ? "translateY(0)" : "translateY(24px)", md: key % 2 === 0 ? "translateY(0)" : "translateY(24px)" }}}>
        <CardColor
          mt={0}
          color={color}
          icon={icon}
          title={title}
          description={description}
        />
      </Grid>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Seo title={metadata.title} description={metadata.description} />
      <Header />
      <HeroLanding
        title={<Trans>ESGeverything:<br />Your Ultimate ESG Audit Tool</Trans>}
        description={
          <Trans>
            A comprehensive ESG audit tool to assess portfolios of Funds, ETFs,
            Equities, Corporate Bonds, Government Bonds, Green and Social Bonds.
          </Trans>
        }
        handleOnclickInfo={() => setShowModal(true)}
        multimedia={heroImg}
        widthImage="100%"
        kindImg={heroKindImg}
        kind="ESGeverything"
        kindColor={theme.palette.colors.green.dark}
        grid={{
          columnLeft: { md: 6 },
          columnRight: { md: 6 },
        }}
      />
      <Box backgroundColor={theme.palette.colors.grey.light}>
        <Container py={{ xs: 8, md: "80px" }}>
          <Stack flexDirection="column" alignItems="center" spacing={3}>
            <Stack spacing={1}>
              {preTitle({ text: "ESGeverything" })}
              <Typography variant="h2" textAlign="center">
                <Trans>The benefits of using ESGeverything</Trans>
              </Typography>
            </Stack>
            <Box pt={1} pb={2}>
              <AnimatedDiv isMobile={isMobile} direction="down">
                <Grid container spacing={2} justifyContent="center">
                  {CARDS_ITEMS.map((item, key) => renderCard({ ...item, key }))}
                </Grid>
              </AnimatedDiv>
            </Box>
          </Stack>
        </Container>
      </Box>
      <Container py={{ xs: 8, md: 14.5, lg: 8 }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          flexWrap={{ xs: "wrap-reverse" }}
        >
          <Grid
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <video
              muted
              autoPlay
              playsInline
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            >
              <source src={searchVideo} type="video/mp4" />
            </video>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack spacing={2}>
              <Stack spacing={1}>
                {preTitle({ text: "Propietary ESG Ratings", align: "left" })}
                <Typography variant="h2">
                  <Trans>Search ESG Ratings Easily</Trans>
                </Typography>
              </Stack>
              <Typography>
                <Trans>
                  Managing your sustainability risks has never been so
                  straightforward, with ESG analysis offered across equities,
                  multi-asset funds, ETFs & Index funds and corporate,
                  government, supranational and thematic bonds.
                </Trans>
              </Typography>
              <Box
                display="flex"
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                {renderRequestButton()}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Container py={{ xs: 8, md: 14.5, lg: 8 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Stack spacing={1}>
                {preTitle({
                  text: "Exclusions and Controversial",
                  align: "left",
                })}
                <Typography variant="h2">
                  <Trans>Behaviour monitoring</Trans>
                </Typography>
              </Stack>
              <Typography>
                <Trans>
                  With global regulatory landscapes ever-evolving, ESGeverything
                  is equipped to streamline your ESG due-diligence and
                  regulatory compliance, with simple solutions, that are all in
                  one place.
                </Trans>
              </Typography>
              <Box
                display="flex"
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                {renderRequestButton()}
              </Box>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <img
              src={monitoringImg}
              alt="Share Class Radar"
              width="100%"
              height="100%"
            />
          </Grid>
        </Grid>
      </Container>
      <Container py={{ xs: 8, md: 14.5, lg: 8 }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          flexDirection={{ xs: "row-reverse" }}
        >
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Stack spacing={1}>
                {preTitle({
                  text: "Portfolio analysis and non-financial",
                  align: "left",
                })}
                <Typography variant="h2">
                  <Trans>Reporting solutions</Trans>
                </Typography>
              </Stack>
              <Typography>
                <Trans>
                  ESGeverything’s portfolio analysis tool is where we bring life
                  to all platform solutions Offering an invaluable way to gain a
                  ‘sustainable oversight’ of your entire portfolio and enhance
                  your ESG reporting and regulatory compliance with the click of
                  a button.
                </Trans>
              </Typography>
              <Box
                display="flex"
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                {renderRequestButton()}
              </Box>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <img
              src={reportingImg}
              alt="Share Class Radar"
              width="80%"
              height="100%"
            />
          </Grid>
        </Grid>
      </Container>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Esg Everything"
          product="esgeverything"
          hasTemplate={false}
        />
      )}
    </ThemeProvider>
  );
}

export default EsgEverything;
