import React from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
const getAnimationProps = (direction, viewportAmount = 0) => ({
  initial: "hidden",
  whileInView: "visible",
  viewport: { once: true, amount: viewportAmount },
  transition: { duration: 0.8 },
  variants: {
  hidden: {
      opacity: 0,
      x: direction === "left" ? -100 : direction === "right" ? 100 : 0,
      y: direction === "down" ? 70 : direction === "up" ? -70 : 0,
      },
      visible: { opacity: 1, x: 0, y: 0 },
  },
});

function AnimatedDiv({ direction, viewportAmount, children }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    if (isMobile) {
        return <div>{children}</div>;
    }
    return <motion.div {...getAnimationProps(direction, viewportAmount)}>{children}</motion.div>;
  }
export default AnimatedDiv;
