import React from "react";
import {
  Stack,
  Typography,
} from "@mui/material";
import { theme } from "../themeV2";

function CardColor({ color, icon, title, description, IsDarkMode, mt = "24px" }) {
  return (
    <Stack
      spacing={2}
      sx={{
        p: 2,
        height: "100%",
        borderRadius: "10px",
        border: `1px solid ${color}`,
        marginTop: `${mt} !important`,
      }}
    >
      <img alt={title} width={24} height={24} src={icon} />
      <Typography fontWeight={700}>{title}</Typography>
      <Typography color={IsDarkMode && theme.palette.colors.white.main}>{description}</Typography>
    </Stack>
  );
}

export default CardColor;
